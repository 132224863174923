
import { Link } from "react-router-dom"
export default function Footer(props) {
    const { user } = props

    const links = [
        {
            text: 'Home',
            url: user ? 'main' : '/',
            target: '_self'
        },
        {
            text: 'Manual',
            url: 'https://ora.oou.cmu.ac.th/sdgskeywordmapping/'
        },
        {
            text: 'Contact',
            url: 'https://m.me/rac.cmu/'
        },
        {
            text: 'Privacy',
            url: '/privacy',
            target: '_self'
        },
        {
            text: 'Power BI',
            url: '/power-bi',
            target: '_self'
        },
        {
            text: "Satisfaction Survey",
            url: "https://cmu.to/survey-sdg-keyphrase",
            target: "_blank"
        }
    ]
    return (
        <div className="border-top bg-body-tertiary d-flex flex-column justify-content-center">
            <div className="d-flex flex-column flex-md-row justify-content-center gap-3 p-3 ">
                {links.map((link, index) => {
                    return <a key={index} target={link.target || '_blank'} className="text-secondary text-decoration-none" href={link.url} rel="noopener noreferrer" >{link.text}</a>
                })}
                {user && user.roles && user.roles.includes('administrator') && (
                    <>
                        <Link className="text-secondary text-decoration-none" to="/users">Users</Link>
                    </>
                )}
            </div>
            <div className="d-flex justify-content-center text-secondary">
                <div className="border-top p-3">{`© 2023 Office of Research Administration`}</div>
            </div>
        </div>
    )


}