import Footer from './Footer'
import { Link } from 'react-router-dom'
export default function HowDoesItWork({ user }) {
    return <div className="min-vh-100 d-flex flex-column">
        <div className='container'>

            {user && (
                <a href={`/main`}>Home</a>
            )}
            {!user && (
                <a href={`/`}>Home</a>
            )}
        </div>
        <div className="flex-grow-1 container">
            <h1>How does it work?</h1>
            <p>This page will explain how this system works and how it differs from Elsevier's SDGs queries mapping 2023 and Elsevier SDGs labels 2023.</p>
            <p>Firstly, <strong>Elsevier's SDGs queries mapping 2023</strong><sup>1</sup> is a set of queries published by Elsevier to retrieve a collection of publications related to each SDG. The system receives a query as input and returns a collection of publications as output. Secondly, <strong>Elsevier SDGs labels 2023</strong><sup>2</sup> is an indicator system that indicates which SDG a given publication is related to. The indicator system takes title, abstract, and keywords as inputs and returns a set of SDGs.</p>
            <p>However, this program is a reverse engineer of Elsevier's SDGs queries mapping 2023. It takes title, abstract, and keywords as inputs and then emulates Elsevier's querying engine, returning how much the inputs are related to each SDG. Thus, the results may vary from the SDG labels in Elsevier's platform.</p>
            <h2>References</h2>
            <ol>
                <li>
                    <Link to="https://elsevier.digitalcommonsdata.com/datasets/y2zyy9vwzy/1">Elsevier's SDGs queries mapping 2023</Link>
                </li>
                <li>
                    <Link to="https://service.elsevier.com/app/answers/detail/a_id/31662/supporthub/scopuscontent/#panel401">Elsevier's SDG labels 2023</Link>
                </li>
            </ol>
        </div>
        <Footer user={user} />
    </div>
}