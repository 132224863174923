import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import jwtDecode from 'jwt-decode'
import { useEffect } from "react";
import axios from "axios";

export default function LoginAsGuest(props) {
    const navigate = useNavigate()
    const { setUser, setToken } = props

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/guest-login`)
                if (response.status === 200) {
                    const { access_token } = response.data
                    const user = jwtDecode(access_token)
                    setToken(access_token)
                    setUser(user)
                    localStorage.setItem('session', access_token)
                    navigate('/main')
                }
            } catch (exception) {
                const message = "Unable to process your request."
                alert(message)
            }
        }
        fetchData()
    }, [])

    return <div className="vh-100 d-flex flex-column justify-content-center align-items-center gap-3">
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div>Logging in as Guest...</div>
    </div>
}