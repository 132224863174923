import { Container, Button } from "react-bootstrap"
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Home() {
    const [allowGuest, setAllowGuest] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/config`)

                if (response.status === 200) {
                    setAllowGuest(response.data.ENABLE_GUEST)
                }
            } catch (exception) {
                const message = 'Unable to process your request.'
                alert(message)
            }
        }
        fetchData()
    }, [])
    return <div className="d-flex flex-column">
        <Container className="vh-100 flex-grow-1 d-flex flex-column justify-content-center align-items-center gap-3 pb-3">
            <img src="/fn1-01_0.png" className="img-fluid w-50 w-sm-100" style={{ maxWidth: '300px' }} alt="" />
            <div className="d-block d-sm-none text-secondary mb-3">Continue with</div>
            <div className="d-flex flex-row flex-sm-column gap-3 align-items-center">
                <Button href="/login"><div className="d-none d-sm-inline">Continue with </div>CMU Account</Button>

                {allowGuest && (
                    <Link className="btn btn-secondary" to="/login-as-guest" target="_self"><div className="d-none d-sm-inline">Continue as </div>Guest</Link>
                )}
            </div>
        </Container>

        <Footer />
    </div>
}