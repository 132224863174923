import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios'

function OAuthCallback(props) {
    const { setToken, setUser } = props
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const query = new URLSearchParams(location.search)
            const code = query.get('code')

            try {
                const response = await axios.post('/api/get_token', {
                    code
                })
                const { token } = response.data
                const user = jwtDecode(token)
                localStorage.setItem('session', token)
                setToken(token)
                setUser(user)
                navigate('/main')
            } catch (error) {
                alert("Sorry, unable to process your request.");
                console.error(error)
                navigate('/')
            }
        }

        fetchData()
    }, [])

    return (
        <div className="vh-100 d-flex flex-column justify-content-center align-items-center gap-3">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Logging in...</div>
        </div>
    )
}

export default OAuthCallback