import Footer from "./Footer";

export default function NotFound() {
    return (
        <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center gap-4">
            <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center gap-4">
                <div>Not Found</div>
            </div>
            <Footer />
        </div>
    )
}