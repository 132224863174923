import React, { useState } from "react";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import Main from "./Main"
import Login from './Login'
import Logout from "./Logout";
import LoginAsGuest from "./LoginAsGuest";
import OAuthCallback from "./OAuthCallback";
import PowerBI from "./PowerBI";
import jwtDecode from 'jwt-decode'
import './App.scss'
import Privacy from "./Privacy";
import Home from "./Home";
import NotFound from "./NotFound"
import User from "./User";
import Setting from "./Setting";
import HowDoesItWork from "./HowDoesItWork";

function App() {
    const [token, setToken] = useState(
        localStorage.getItem('session')
    )

    const [user, setUser] = useState(token ? jwtDecode(token) : undefined)

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='/setting' element={<Setting user={user} token={token} />} />
                    <Route path='how-does-it-work' user={user} element={<HowDoesItWork user={user} />} />
                    <Route path="/login-as-guest" element={<LoginAsGuest setToken={setToken} setUser={setUser} />} />
                    <Route path="/main" element={<Main token={token} user={user} />} />
                    <Route path='/power-bi' element={<PowerBI user={user} token={token} />} />
                    <Route path='/logout' element={<Logout setUser={setToken} setToken={setToken} />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/oauthcallback' element={<OAuthCallback setToken={setToken} setUser={setUser} />} />
                    <Route path='/privacy' element={<Privacy user={user} />} />
                    <Route path='/users' element={<User user={user} token={token} />} />
                    <Route path="*" element={<NotFound user={user} />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
