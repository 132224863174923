export default function UserNav(props) {
    const { user } = props
    return user && (
        <div className="card mb-3">
            <div className="card-body d-flex flex-row justify-content-between">
                <div>Currenly logged-in as {user.email}</div>
                <a href="/logout" className="link-primary">Logout</a>
            </div>

        </div>
    )
}