import React from "react";
import ReactDOM from 'react-dom'

import App from './App';
import './index.css'
import './App.scss'


ReactDOM.render(
  <App />,
  document.getElementById('root')
)