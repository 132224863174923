import React, { useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner';

function Login() {
    const link = `https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=p3AURhUkFv1zPhaxUMmqVCDH6Vm1RjGmtK5gy7fC&redirect_uri=${process.env.REACT_APP_API_OAUTH_CALLBACK}&scope=cmuitaccount.basicinfo&state=auth`
    useEffect(() => {
        window.location = link
    })

    return (
        <div className='vh-100 d-flex flex-column justify-content-center align-items-center gap-3'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Redirecting to CMU OAuth...</div>
            <a className='btn btn-link' href={link} target='_self'>Click here if you are not redirected</a>
        </div>
    )
}

export default Login