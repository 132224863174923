import React, { useState } from "react"
import { Row, Col, Form, Card, Button, Tab, ButtonGroup, Spinner } from 'react-bootstrap'
import { ArrowLeft, ArrowRight, ArrowUp } from 'react-bootstrap-icons'
import TextareaAutosize from 'react-textarea-autosize'
import camelcaseKeys from 'camelcase-keys'
import UserNav from "./UserNav"
import Nav from 'react-bootstrap/Nav';
import Footer from "./Footer"
import axios from "axios"
import Disclaimer from "./Disclaimer"

function removeLeadingZeros(input) {
    // Use regular expressions to remove leading zeros
    return input.replace(/0+(\d+)/, '$1');
}

function NavigatorButton() {
    const classSelector = 'fade tab-pane active show'
    const [maskIndex, setMaskIndex] = useState(0)
    function scrollToTop() {
        setMaskIndex(0)
        document
            .getElementsByClassName('tab-content')[0]
            .scrollTo({ top: 0 })
    }
    function previous() {
        if (maskIndex === 0) {
            return
        }
        document
            .getElementsByClassName(classSelector)[0]
            .getElementsByTagName('mark')[maskIndex]
            .scrollIntoView()
        setMaskIndex(maskIndex - 1)
    }
    function next() {
        const isExceedIndex = (
            maskIndex === document
                .getElementsByClassName(classSelector)[0]
                .getElementsByTagName('mark')
                .length
        )
        if (isExceedIndex) {
            return 0
        }
        document
            .getElementsByClassName(classSelector)[0]
            .getElementsByTagName('mark')[maskIndex]
            .scrollIntoView()
        setMaskIndex(maskIndex + 1)
    }
    return (
        <div className="d-flex flex-row justify-content-between">
            <div>
                <Button variant="secondary" onClick={scrollToTop} >
                    <ArrowUp />
                </Button>
            </div>
            <div>
                <ButtonGroup aria-label="Basic example" className="">
                    <Button variant="secondary" onClick={previous}>
                        <ArrowLeft />
                    </Button>
                    <Button variant="secondary" onClick={next}>
                        <ArrowRight />
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    )
}


class Main extends React.Component {

    constructor(props) {
        super(props)

        if (!this.props.user) {
            window.location.replace("/");
        }

        this.state = {
            isLoading: false,
            demoPaper: {
                title: 'Basic income, wealth inequality and welfare: A proposed case in New Zealand',
                abstract: 'Universal basic income (UBI) may be defined as a government programme that regularly distributes a set amount of income to every citizen. While many countries currently adopt need-based programmes, the idea of introducing a UBI programme has been discussed politically in several countries. For instance, The Opportunity Party in New Zealand proposed paying NZ$13,000 per year to every adult citizen as basic income. Unless the amount of transfer per person decreases under the new programme, the government will have to increase tax rates. If a difference exists in labour supply and saving responses to the increases in tax rates among households, wealth distribution will change. This study examines the details of the proposed UBI programme and demonstrates that it will increase wealth inequality across households and decrease the welfare of different types of households classified by wage level, gini coefficient, pedestrian safety, internet-based learning environment and mobile device.',
                keywords: ['Universal basic income', 'Gini coefficients', 'Heterogeneous-agent macroeconomic models', 'Incomplete market models', 'sobriety test']
            },
            paper: {
                title: '',
                abstract: '',
                keywords: ''
            },
            result: undefined,
            results: [],
            mode: 'input'
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleAbstractChange = this.handleAbstractChange.bind(this)
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this)
        this.handleRuleChange = this.handleRuleChange.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.clearForm = this.clearForm.bind(this)
    }



    handleBack() {
        this.setState({ mode: 'input', result: undefined })
    }

    handleTitleChange(event) {
        this.setState({ paper: { ...this.state.paper, title: event.target.value } })
    }
    handleAbstractChange(event) {
        this.setState({ paper: { ...this.state.paper, abstract: event.target.value } })
    }
    handleKeywordsChange(event) {
        this.setState({ paper: { ...this.state.paper, keywords: event.target.value } })
    }

    handleRuleChange(event) {
        this.setState({ rule: event.target.value })
    }

    clearForm() {
        this.setState({
            paper: {
                title: '',
                abstract: '',
                keywords: ''
            }
        })
    }

    async handleSubmit() {
        const { token } = this.props
        this.setState({
            isLoading: true
        })
        const data = {
            ...this.state.paper,
            keywords: this.state.paper.keywords
                .split(',')
                .map(keyword => keyword.trim())
        }
        const requestOptions = {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }

        try {
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/execute`
            const results = await axios
                .post(url, data, requestOptions)
                .then(response => {
                    const { data } = response
                    return camelcaseKeys(data)
                })

            this.setState({
                results: results,
                result: results[0],
                mode: 'result',
                isLoading: false
            })
        } catch (error) {
            const message = "Unable to process your request, please try again."
            alert(message)
            console.error(error)
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div
                        className="row"
                    >
                        {/* begin left column */}
                        <div className="col-12 col-sm-6">
                            <div className="mt-3">
                                <UserNav user={this.props.user} />
                            </div>
                            <Form>
                                <Card>
                                    <Card.Body>
                                        <Form.Group className="">
                                            <Form.Label className="fw-bold">Title</Form.Label>
                                            {this.state.mode === 'input' && (
                                                <TextareaAutosize
                                                    value={this.state.paper.title}
                                                    onChange={this.handleTitleChange}
                                                    placeholder="Input title here"
                                                    className="form-control"
                                                    disabled={this.state.isLoading}
                                                />
                                            )}
                                            {this.state.mode === 'result' && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.result.labeledPaper.title
                                                    }} />
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mt-2">
                                            <Form.Label className="fw-bold">Abstract</Form.Label>
                                            {this.state.mode === 'input' && (
                                                <TextareaAutosize
                                                    value={this.state.paper.abstract}
                                                    onChange={this.handleAbstractChange}
                                                    placeholder="Input abstract here"
                                                    className="form-control"
                                                    disabled={this.state.isLoading}
                                                />
                                            )}
                                            {this.state.mode === 'result' && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.result.labeledPaper.abstract
                                                    }} />
                                            )}

                                        </Form.Group>
                                        <Form.Group className="mt-2">
                                            <Form.Label className="fw-bold">Keywords</Form.Label>
                                            {this.state.mode === 'input' && (
                                                <TextareaAutosize
                                                    value={this.state.paper.keywords}
                                                    onChange={this.handleKeywordsChange}
                                                    placeholder="Keyword1, keyword2"
                                                    className="form-control"
                                                    disabled={this.state.isLoading}
                                                />
                                            )}
                                            {this.state.mode === 'result' && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.result.labeledPaper.keywords.join(', ')
                                                    }} />
                                            )}
                                        </Form.Group>

                                    </Card.Body>
                                </Card>

                                <Form.Group className="mt-3">
                                    {this.state.mode === 'input' && (
                                        <Row>
                                            <Col className="d-grid gap-2">
                                                <Button
                                                    onClick={this.handleSubmit}
                                                    disabled={this.state.isLoading || !(this.state.paper.title !== '' || this.state.paper.abstract !== '' || this.state.paper.keywords !== '')}
                                                >
                                                    {this.state.isLoading ? 'Processing...' : 'Submit'}
                                                </Button>
                                            </Col>
                                            {!this.state.isLoading && (
                                                <Col className="d-grid gap-2" xs={3}>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={this.clearForm}
                                                        disabled={(this.state.paper.title === '' && this.state.paper.abstract === '' && this.state.paper.keywords === '') || this.state.isLoading}
                                                    >Clear</Button>
                                                </Col>
                                            )}

                                        </Row>
                                    )}
                                    {this.state.mode === 'result' && (
                                        <div className="d-grid gap-2">
                                            <Button
                                                variant="secondary"
                                                onClick={this.handleBack}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    )}
                                </Form.Group>


                            </Form>

                            {
                                this.state.mode && this.state.mode === 'input' && !this.state.isLoading && (
                                    <div className="d-grid mt-3">
                                        <Button
                                            variant="outline-primary"
                                            onClick={e => { this.setState({ paper: { ...this.state.demoPaper, keywords: this.state.demoPaper.keywords.join(', ') } }) }}

                                        >Insert Demo Paper</Button>

                                    </div>

                                )
                            }
                            <Disclaimer />
                        </div>
                        {/* end left column */}
                        {/* being right column */}
                        <div className="col-12 col-sm-6">
                            <div className="d-flex flex-column vh-100">
                                {this.state.mode === 'input' && !this.state.isLoading && (
                                    <div className="card my-3 text-center">
                                        <div className="card-body">
                                            Result will be shown here
                                        </div>
                                    </div>
                                )}

                                {this.state.mode === 'input' && this.state.isLoading && (
                                    <div className="h-100 gap-3 d-flex flex-column justify-content-center align-items-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Processing...</span>
                                        </Spinner>
                                        <div>Processing...</div>
                                    </div>
                                )}
                                {this.state.mode === 'result' && this.state.results && this.state.results.length > 0 && (
                                    <Tab.Container
                                        defaultActiveKey={0}
                                        id="result-tab"
                                        onSelect={key => {
                                            const { labeledPaper } = this.state.results[key]
                                            this.setState({
                                                result: {
                                                    labeledPaper
                                                }
                                            })
                                        }}
                                    >
                                        <Nav className="flex-row order-1 my-3" variant="pills">
                                            {this.state.results && this.state.results.map((result, index) => {
                                                return <Nav.Item
                                                    key={index}
                                                >
                                                    <Nav.Link
                                                        eventKey={index}
                                                        className="d-flex gap-2"

                                                    >
                                                        <div>{`SDG` + removeLeadingZeros(result.sdgNo)}</div>
                                                        <div className="d-flex align-items-center">
                                                            <span className={`badge ${result.exactMatched ? 'text-bg-success' : 'text-bg-light'}`}>{result.partialMatchedNo}</span>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            })}
                                        </Nav>
                                        <Tab.Content className="overflow-y-scroll order-3 order-sm-2 card">
                                            {this.state.results && this.state.results.map((result, index) => {
                                                return <Tab.Pane
                                                    eventKey={index}
                                                    key={index}
                                                    className="card-body"
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.labeledRule
                                                        }}
                                                    />
                                                </Tab.Pane>
                                            })}
                                        </Tab.Content>
                                    </Tab.Container>
                                )}

                                {this.state.mode === 'result' && (
                                    <div className="order-2 order-sm-3 my-3">
                                        <NavigatorButton />
                                    </div>
                                )}

                            </div>
                        </div>
                        {/* end right column */}
                    </div>
                </div >
                <Footer user={this.props.user} />
            </>
        )
    }
}

export default Main;