import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "./Footer";

export default function Setting(props) {
    const { user, token } = props
    const [allowGuest, setAllowGuest] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/config`)

                if (response.status === 200) {
                    setAllowGuest(response.data.ENABLE_GUEST)
                    setIsLoading(false)
                }

            } catch (exception) {
                const message = 'Unable to process your request'
                alert(message)
            }
        }
        fetchData()
    }, [])

    const handleGuestCheck = function (event) {
        const { checked } = event.target
        const fetchData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/config`, {
                    ENABLE_GUEST: checked
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

                if (response.status === 200) {
                    setAllowGuest(checked)
                }
            } catch (exception) {
                const message = 'Unable to process your request.'
                alert(message)
            }
        }
        fetchData()
    }

    if (isLoading) {
        return <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">Loading...</div>
    }

    return <div className="d-flex flex-column min-vh-100 gap-4">
        <Container className="flex-grow-1 d-flex flex-column p-4">
            <Link to='/main'>Home</Link>
            <h1>Setting</h1>
            <h2>System</h2>
            <Form>
                <Form.Check
                    type='switch'
                    id='guest-switch'
                    label='Allow guest account'
                    onChange={(e) => { handleGuestCheck(e) }}
                    checked={allowGuest}
                />
            </Form>
            <h2>Current user</h2>
            <code>{JSON.stringify(user)}</code>
        </Container>
        <Footer user={user} />
    </div>
}