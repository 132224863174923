import Footer from "./Footer"

export default function Privacy(props) {
    const { user } = props
    return <div className="min-vh-100 d-flex flex-column">
        <div className="container p-4 flex-grow-1">
            <a href="/main">Home</a>
            <h1 className="">Privacy Notice</h1>
            <div>
                <ul>
                    <li>Submitted titles, abstracts, and keywords are <u>never</u> collected.</li>
                    <li>For CMU users, logged-in date-time histories and matching results (i.e., TRUE or FALSE) will be collected.</li>
                </ul>
            </div>
        </div>
        <Footer user={user} />
    </div>
}