import { Container, DropdownButton, Dropdown, Badge, ListGroup } from "react-bootstrap"
import axios from "axios"
import Footer from "./Footer"
import { Link } from "react-router-dom"
import useSWR from 'swr'

export default function User(props) {
    const { token, user } = props

    const url = `${process.env.REACT_APP_API_BASE_URL}/api/users`
    const fetcher = (url) => {
        return axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => response.data)
    }
    const { data, error, isLoading, mutate } = useSWR(url, fetcher)

    const canRevokeAdministrator = data && data.filter(user => user.roles && user.roles.includes('administrator')).length > 1

    const headers = {
        Authorization: `Bearer ${token}`
    }

    const appendUserRole = async (userId, role) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/add-role`, { role }, {
                headers
            })

            if (response.status === 200) {
                mutate()
            }

        } catch (error) {
            console.error(error)
            alert(error.response.data.message || 'Unable to process your request.')
        }
    }

    const removeUserRole = async (userId, role) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/${userId}/remove-role`, { role }, { headers })

            if (response.status === 200) {
                mutate()
            }
        } catch (error) {
            console.error(error)
            alert(error.response.data.message || 'Unable to process your request.')
        }
    }

    if (error) {
        return <diu className="min-vh-100 d-flex flex-column justify-content-center align-items-center gap-3">
            <div>Sorry, unable to process your request</div>
            <Link href="/">Back</Link>
        </diu>
    }

    if (isLoading) {
        return <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
            <div>Loading...</div>
        </div>
    }

    return <div className="d-flex flex-column min-vh-100">
        <Container className="flex-grow-1 d-flex flex-column p-4">
            <Link to="/main">Home</Link>
            <h1>Users</h1>

            <ListGroup>
                {data && data.map((user, index) => {
                    return <ListGroup.Item className="d-flex justify-content-between p-3" key={index}>
                        <div className="d-flex flex-column gap-2">
                            <div>{user.email}</div>
                            <div className="hstack gap-2">
                                {user && user.roles && user.roles.sort().map((role, index) => {
                                    return <Badge key={index} bg="secondary">{role}</Badge>
                                })}
                            </div>
                        </div>

                        <div>
                            <DropdownButton
                                title={`Setting`}
                                variant="outline-secondary"
                                id={`dropdown-${user.id}`}
                                size="sm"
                            >

                                {user && user.roles && !user.roles.includes('administrator') && (
                                    <Dropdown.Item
                                        onClick={() => { appendUserRole(user.id, 'administrator') }}
                                    >Assign administrator role</Dropdown.Item>
                                )}

                                {user && user.roles && user.roles.includes('administrator') && canRevokeAdministrator && (<Dropdown.Item
                                    onClick={() => { removeUserRole(user.id, 'administrator') }}
                                >Revoke administrator role</Dropdown.Item>
                                )}

                                {user && user.roles && !user.roles.includes('reporter') && (
                                    <Dropdown.Item
                                        onClick={() => { appendUserRole(user.id, 'reporter') }}
                                    >Assign reporter role</Dropdown.Item>
                                )}

                                {user && user.roles && user.roles.includes('reporter') && (<Dropdown.Item
                                    variant="outline-primary"
                                    onClick={() => { removeUserRole(user.id, 'reporter') }}
                                >Revoke reporter role</Dropdown.Item>
                                )}
                            </DropdownButton>
                        </div>
                    </ListGroup.Item>
                })}
            </ListGroup>
        </Container>
        <Footer user={user} />
    </div>
}