import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from "react";

function Logout(props) {
    const navigate = useNavigate()
    const { setToken, setUser } = props

    useEffect(() => {
        setTimeout(() => {
            setToken(undefined)
            setUser(undefined)
            localStorage.removeItem('session')
        }, 100)
        navigate('/');
    }, [navigate, setToken, setUser])

    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-3 vh-100 ">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Logout...</div>
        </div>
    )
}

export default Logout