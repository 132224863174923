import Footer from "./Footer"

export default function PowerBI({ token, user }) {
    const schema = `erDiagram
    users {
        int id PK
        string email UK
        string faculty_code
        datetime created_at
    }
    submissions {
        int id PK
        int user_id FK
        datetime created_at
    }
    results {
        int id PK
        string sdg_no
        boolean partial_matched
        boolean exact_matched 
        int submission_id FK
        datetime created_at
    }
    user_logged_in_logs {
        int id PK
        int user_id FK
        datetime created_at
    }
    users ||--|{ user_logged_in_logs: logins
    users ||--o{ submissions: submits
    submissions ||--|{ results: contains
`

    const hasReporterRole = user && user.roles && user.roles.includes('reporter')

    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/reports`
    return <div className="d-flex flex-column min-vh-100">
        <div className="container p-4 flex-grow-1">
            {token && (
                <a href="/main">Home</a>
            )}

            {!token && (
                <a href="/">Home</a>
            )}

            {!hasReporterRole && (
                <div className="mt-3 alert alert-warning" role="alert">
                    <strong>Warning</strong>, you do not have permission to use this feature.
                </div>
            )}
            <h1>Power BI</h1>
            <h2>Getting Started</h2>
            <ol>
                <li>Import report dataset as <a href="https://learn.microsoft.com/en-us/power-bi/connect-data/desktop-data-sources">web data source</a>.</li>
                <li>Set the URL to one of the following links:</li>
                <ul>
                    <li>Users <a href={`${baseUrl}/users`}>{baseUrl}/users</a></li>
                    <li>Submissions <a href={`${baseUrl}/submissions`}>{baseUrl}/submissions</a></li>
                    <li>Results <a href={`${baseUrl}/results`}>{baseUrl}/results</a></li>
                    <li>User logged-in histories <a href={`${baseUrl}/user-logged-in-histories`}>{baseUrl}/user-logged-in-histories</a></li>
                </ul>
                <li>Set <code className="border px-1">Authorization</code> header to <code className="border px-1">Bearer {hasReporterRole ? token : 'ACCESS_DENIED'}</code></li>
            </ol>
            <h2>Schema</h2>
            <div>Users have an email and a faculty_code. Each user can submit submissions, and each submission contains 17 results related to the 17 categories of SDGs. Each result includes boolean values for exact matches and partial matches.</div>
            <div>A schema is shown on <a rel="noreferrer" target="_blank" href="https://mermaid.live/edit#pako:eNqdUk1rwzAM_SvC5_YP-Dx26WVQdgsE1VZSgz-KLcNKkv8-eQ1pC90Ou5gnIT09PXlSJllSWlF-czhmDFAL5QITuMjgLHwcoHB2cQQK6Dx8bvGApnq-9o0BLDKxCwQmk0DbI8MCpZ6CK8Wl-MzYUJvTS_h--KU5UxH-l1KKHfuY4JSSJ4xwwcwOfR-QzZnslqcvNLxlG8td0J-jf7T5NI6ScLGhf-i_GTnP-_08vWLUIK8TZ-6FaXq0TN8CseDRx5VwdUeDSZFRaNROBcpyIyvnnLoI0Ck-U6BOaYGWBpSGTnVxkVKsnI7XaJTmXGmn6qUtsf4BpQf0hZZvjHDFgg">mermaid.live</a>.</div>

            <div className="border px-1">
                <code>{schema}</code>
            </div>
        </div>
        <Footer user={user} />
    </div>
}